import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import side from "../assets/images/side.png";

function Header(props) {
  const [show, setshow] = useState(false);

  const [show1, setshow1] = useState(false);

  const [show2, setshow2] = useState(false);

  const [show3, setshow3] = useState(false);

  useEffect(() => {
    datas1();
    datas2();
    datas3();
  }, []);

  const api = "https://api.thefortuneimmigrations.com/";

  const [Study, setStudy] = useState([]);

  const [visits, setvisits] = useState([]);

  const [migrates, setmigrates] = useState([]);

  const datas1 = () => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getallmigrates",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setmigrates(res.data.migrates);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const datas2 = () => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getallvisits",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setvisits(res.data.visits);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const datas3 = () => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getallstudys",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setStudy(res.data.studys);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const databyid = (data) => {
    sessionStorage.setItem("study", data._id);
    window.location.href = "/study";
  };

  const databyid1 = (data) => {
    sessionStorage.setItem("migrate", data._id);
    window.location.href = "/migrate";
  };

  const databyid2 = (data) => {
    sessionStorage.setItem("visits", data._id);
    window.location.href = "/visit";
  };

  const shows = show == true ? "block" : "";

  const shows1 = show1 == true ? "block" : "";

  const shows2 = show2 == true ? "block" : "";

  const shows3 = show3 == true ? "block" : "";

  const [Open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleShow = () => setOpen(true);

  useEffect(() => {
    const interval = setInterval(() => {
      handleShow();
    }, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const [form, setform] = useState([]);

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addcontactForm();
  };

  const addcontactForm = () => {
    const dataArray = {
      name: form.name,
      mobileNumber: form.mobileNumber,
      email: form.email,
      message: form.message,
    };

    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/request/addrequest",
        dataArray,
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast("I'll get back to you soon");
            setform({
              name: "",
              mobileNumber: "",
              email: "",
              message: "",
            });
            handleClose();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      <section class="topbar01">
        <div class="topLeftShape">
          <svg
            width="197"
            height="28"
            viewBox="0 0 197 28"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="1" width="196" height="26" fill="#fff" />
            <path d="M171 1L196.5 27.5V1H171Z" fill="var(--heading-color)" />
            <path d="M25.5 0L0 26.5V0H25.5Z" fill="var(--heading-color)" />
          </svg>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="topBarWrapper">
                <div class="infoText">
                  <i class="flaticon-email"></i>
                  <a href={`mailto:${props.contactus.email}`} target="_blank">
                    {props.contactus.email}
                  </a>
                </div>

                <div class="infoText">
                  <i class="flaticon-phone-call"></i>
                  <a href={`tel:${props.contactus.phone}`}>
                    {" "}
                    {props.contactus.phone}
                  </a>
                </div>

                <div class="infoText">
                  <i class="flaticon-clock"></i>Mon _ Sat:{" "}
                  {props.contactus.fromTime} to {props.contactus.toTime}
                </div>

                <div class="topBarSocial">
                  <a href={props.contactus.facebook} target="_blank">
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href={props.contactus.twitter} target="_blank">
                    <i class="fa-brands fa-twitter"></i>
                  </a>
                  <a href={props.contactus.instagram} target="_blank">
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href={`https://api.whatsapp.com/send/?phone=${props.contactus.whatsapp}&text=Hi&type=phone_number&app_absent=0`}
                    target="_blank"
                  >
                    <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>

                <a class="linkText">
                  <NavLink to="/Contact" style={{ color: "white" }}>
                    {" "}
                    Help / Support{" "}
                  </NavLink>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="topRightShape">
          <svg
            width="197"
            height="28"
            viewBox="0 0 197 28"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="1" width="196" height="26" fill="#fff" />
            <path d="M171 1L196.5 27.5V1H171Z" fill="var(--heading-color)" />
            <path d="M25.5 0L0 26.5V0H25.5Z" fill="var(--heading-color)" />
          </svg>
        </div>
      </section>

      <header class="header01 isSticky">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="header01Inner">
                <div class="logo01">
                  <a>
                    <NavLink to="/">
                      {" "}
                      <img
                        src={api + props.contactus.logo}
                        alt="thefortuneimmigrations"
                      />{" "}
                    </NavLink>
                  </a>
                </div>

                <a
                  class="menuBtn"
                  type="button"
                  onClick={() => {
                    setshow(!show);
                  }}
                >
                  <i class="fa-solid fa-bars"></i>
                </a>
                <nav class="mainMenu" style={{ display: `${shows}` }}>
                  <ul class="sub-menu">
                    <li>
                      <NavLink to="/">
                        <a>Home</a>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/aboutus">
                        <a>About Us</a>
                      </NavLink>
                    </li>
                    <li class="menu-item-has-children">
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          setshow1(!show1);
                        }}
                      >
                        {" "}
                        Study
                      </a>
                      <ul class="sub-menu" style={{ display: `${shows1}` }}>
                        {Study.map((data, i) => {
                          return (
                            <li key={i}>
                              <a
                                type="button"
                                onClick={() => {
                                  databyid(data);
                                }}
                              >
                                {data.title}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </li>

                    <li class="menu-item-has-children">
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          setshow2(!show2);
                        }}
                      >
                        Migrate
                      </a>
                      <ul class="sub-menu" style={{ display: `${shows2}` }}>
                        {migrates.map((data, i) => {
                          return (
                            <li key={i}>
                              <a
                                type="button"
                                onClick={() => {
                                  databyid1(data);
                                }}
                              >
                                {data.title}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </li>

                    <li class="menu-item-has-children">
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          setshow3(!show3);
                        }}
                      >
                        Visit
                      </a>
                      <ul class="sub-menu" style={{ display: `${shows3}` }}>
                        {visits.map((data, i) => {
                          return (
                            <li key={i}>
                              <a
                                type="button"
                                onClick={() => {
                                  databyid2(data);
                                }}
                              >
                                {data.title}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </li>

                    <li>
                      <NavLink to="/contact">
                        <a>Contact Us</a>
                      </NavLink>
                    </li>
                  </ul>
                </nav>

                <a class="immiPressBtn" type="button" onClick={handleShow}>
                  <span>Book a Consultation</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="blanks"></div>

      <Modal show={Open} size="lg" onHide={handleClose}>
        <Modal.Header
          closeButton
          style={{ borderBottom: "0px" }}
        ></Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {" "}
                <div class="mb-1 text-center">
                  <h4 style={{ color: "#E94D4E" }}>Request A Call Back</h4>
                  <h6 style={{ color: "#38B0A5" }}> Connect With Us !</h6>
                </div>
              </div>
              <div className="col-md-6" style={{ height: "100%" }}>
                <img src={side} className="pt-3"></img>
              </div>
              <div className="col-md-6">
                <div class="mt-3 mb-3 text-center">
                  <div class="contactForm">
                    <form
                      id="contact_form"
                      onSubmit={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="input-box">
                            <input
                              type="text"
                              placeholder="Enter Name"
                              name="name"
                              required
                              value={form.name}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="input-box">
                            <input
                              type="text"
                              minLength="10"
                              maxLength="10"
                              pattern="[0-9]+"
                              placeholder="Enter Mobile Number"
                              autocomplete="off"
                              name="mobileNumber"
                              required
                              value={form.mobileNumber}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div class="input-box">
                            <input
                              type="email"
                              placeholder="Enter Email"
                              name="email"
                              required
                              value={form.email}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </div>
                        </div>

                        <div class="col-lg-12">
                          <div class="input-box">
                            <textarea
                              placeholder="Message"
                              required
                              name="message"
                              value={form.message}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div>
                        <button
                          type="submit"
                          class="btn  btn-sm"
                          style={{
                            width: "100%",
                            background: "#E94D4E",
                            color: "white",
                          }}
                        >
                          Submit
                        </button>
                        <p class="mt-2">
                          I Agree to{" "}
                          <u>
                            <NavLink to="/Terms">Terms And Conditions</NavLink>
                          </u>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div>
            <div>
              <div className="menu-container1">
                <a
                  href={`https://api.whatsapp.com/send/?phone=${props.contactus.whatsapp}&text=Hi&type=phone_number&app_absent=0`}
                  target="_blank"
                  class="sbutton whatsapp"
                  tooltip="WhatsApp"
                >
                  <i class="fa-brands fa-whatsapp whatsappicon" style={{fontSize:"30px" ,color:"white"}} ></i>
                </a>
              </div>
            </div>
          </div>
    </div>
  );
}

export default Header;
