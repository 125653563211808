import React, { useState, useEffect } from "react";
import CONTACT9 from "../../assets/images/bg/CONTACT9.jpg";
import Footer from "../Footer/Footer";
import Header from "../Header";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Contact() {
  const [contactus, setcontactus] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [form, setform] = useState([]);

  const [visits, setvisits] = useState([]);

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  useEffect(() => {
    datas();
  }, []);

  const api = "https://api.thefortuneimmigrations.com/";

  const datas = () => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setcontactus(res.data.contactus[0]);
            setvisits(res.data.visits);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addcontactForm();
  };

  const addcontactForm = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phone: form.phone,
      subject: form.subject,
      message: form.message,
    };

    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/enquiry/addenquiry",
        dataArray,
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast("I'll get back to you soon");
            setform({
              name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <section class="preloader" id="preloader">
            <div class="spinner-eff spinner-eff-1">
              <div class="bar bar-top"></div>
              <div class="bar bar-right"></div>
              <div class="bar bar-bottom"></div>
              <div class="bar bar-left"></div>
            </div>
          </section>
        </>
      ) : (
        <>
          <Header contactus={contactus} />
          <section
            class="pageBanner"
            style={{ backgroundImage: `url(${api + contactus.bannerImage})` }}
          >
            <div class="overlay"></div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="bannerTitle">Contact Us</h2>
                  <div class="breadcrumbs">
                    <a>Home</a>
                    <span>/</span>Contact
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="contactSection02">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 col-md-6">
                  <div class="contactFWrapper">
                    <h5 class="subTitle">Get in touch</h5>
                    <h2 class="secTitle">{contactus.title}</h2>
                    <div class="contactForm">
                      <form
                        id="contact_form"
                        onSubmit={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        <div class="row">
                          <div class="col-lg-6 col-md-12 col-sm-6">
                            <div class="input-box">
                              <input
                                type="text"
                                placeholder="Name"
                                name="name"
                                required
                                value={form.name}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12 col-sm-6">
                            <div class="input-box">
                              <input
                                type="email"
                                placeholder="Email address"
                                name="email"
                                required
                                value={form.email}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12 col-sm-6">
                            <div class="input-box">
                              <input
                                type="text"
                                minLength="10"
                                maxLength="10"
                                pattern="[0-9]+"
                                placeholder="Phone"
                                autocomplete="off"
                                name="phone"
                                required
                                value={form.phone}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12 col-sm-6">
                            <div class="input-box">
                              <input
                                type="text"
                                placeholder="Subject"
                                required
                                name="subject"
                                value={form.subject}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="input-box">
                              <textarea
                                placeholder="Message"
                                required
                                name="message"
                                value={form.message}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <button type="submit" class="immiPressBtn">
                              <span>Send Message</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 col-md-6">
                  <div
                    class="conInfoWrapper"
                    style={{ backgroundImage: `url(${CONTACT9})` }}
                  >
                    <div class="iconBox05 ib0501">
                      <div class="ibBox">
                        <i class="flaticon-phone-call"></i>
                      </div>
                      <h3 class="ibTitle">Any Questions? Call us</h3>
                      <p>{contactus.phone}</p>
                    </div>

                    <div class="iconBox05">
                      <div class="ibBox">
                        <i class="flaticon-email"></i>
                      </div>
                      <h3 class="ibTitle">Any Questions? Email us</h3>
                      <p>{contactus.email}</p>
                    </div>

                    <div class="iconBox05">
                      <div class="ibBox">
                        <i class="flaticon-location"></i>
                      </div>
                      <h3 class="ibTitle">Address</h3>
                      <p>{contactus.addresss}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mapsSection">
            <div class="container-fluid">
              <div class="row">
                <div class="gmaps">
                  <iframe
                    src={contactus.map}
                    width="600"
                    height="450"
                    frameborder="0"
                    style={{ border: 0 }}
                    allowfullscreen=""
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
          <ToastContainer />
          <Footer contactus={contactus} visits={visits} />
        </>
      )}
    </div>
  );
}

export default Contact;
