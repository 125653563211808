import React, { useState, useEffect } from "react";
import countrybg from "../../assets/images/bg/countrybg.jpg";
import sev1 from "../../assets/images/bg/sev1.jpg";
import { NavLink } from "react-router-dom";
import Footer from "../Footer/Footer";
import Slider from "react-slick";
import Header from "../Header";
import axios from "axios";

function Aboutus() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    Arrows: true,
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    datas();
  }, []);

  const [About, setAbout] = useState([]);

  const [migrates, setmigrates] = useState([]);

  const [teams, setteams] = useState([]);

  const [visits, setvisits] = useState([]);

  const [testimoni, settestimoni] = useState([]);

  const [contactus, setcontactus] = useState([]);

  const api = "https://api.thefortuneimmigrations.com/";

  const datas = () => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            setAbout(res.data.aboutus[0]);
            setteams(res.data.teams);
            setvisits(res.data.visits);
            setmigrates(res.data.migrates);
            settestimoni(res.data.testimoni);
            setcontactus(res.data.contactus[0]);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const databyid1 = (data) => {
    sessionStorage.setItem("migrate", data._id);
    window.location.href = "/migrate";
  };

  const databyid2 = (data) => {
    sessionStorage.setItem("visits", data._id);
    window.location.href = "/visit";
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <section class="preloader" id="preloader">
            <div class="spinner-eff spinner-eff-1">
              <div class="bar bar-top"></div>
              <div class="bar bar-right"></div>
              <div class="bar bar-bottom"></div>
              <div class="bar bar-left"></div>
            </div>
          </section>
        </>
      ) : (
        <>
          <Header contactus={contactus} />

          <section
            class="pageBanner"
            style={{ backgroundImage: `url(${api + About.bannerImage})` }}
          >
            <div class="overlay"></div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="bannerTitle">About Us</h2>
                  <div class="breadcrumbs">
                    <NavLink to="/">
                      <a href="index">Home</a>
                    </NavLink>{" "}
                    <span>/</span>About Us
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="aboutSection01 abPageAbout">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="abVideo">
                    <img src={api + About.image} alt="thefortuneimmigrations" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="aboutContent01">
                    <h5 class="subTitle">{About.title1}</h5>
                    <h2 class="secTitle">{About.tiltle2}</h2>
                  </div>
                </div>
              </div>
              <div class="row pt-5 pb-5">
                <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                  <p>{About.description}</p>
                </div>
              </div>

              <div class="row cusrow">
                <div class="col-md-6 col-lg-12 col-xl-6 col-sm-6">
                  <div class="iconBox02">
                    <div class="ibBox">
                      <img
                        src={api + About.missionImage}
                        style={{ height: "40px" }}
                        alt="thefortuneimmigrations"
                      />
                    </div>
                    <h3 class="ibTitle"> {About.missionTitle}</h3>
                    <p>{About.missionDescription}</p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-12 col-xl-6 col-sm-6">
                  <div class="iconBox02">
                    <div class="ibBox">
                      <img
                        src={api + About.vissionImage}
                        style={{ height: "40px" }}
                        alt="thefortuneimmigrations"
                      />
                    </div>
                    <h3 class="ibTitle">{About.vissionTitle}</h3>
                    <p>{About.vissionDescription}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            class="countrySection01 abPageCountry"
            style={{ backgroundImage: `url(${countrybg})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h5 class="subTitle white">Choose Country</h5>
                  <h2 class="secTitle white">
                    Immigration and citizenship
                    <br />
                    Choose your <span>country!</span>
                  </h2>
                </div>
              </div>
              <div class="row">
                {migrates.map((data, i) => {
                  return (
                    <div class="col-lg-3 col-md-6 col-sm-6" key={i}>
                      <a
                        type="button"
                        onClick={() => {
                          databyid1(data);
                        }}
                      >
                        <div class="countryItem01 text-center">
                          <div class="countryThumb">
                            <img src={api + data.image} alt="thefortuneimmigrations" style={{height:"250px"}} />
                          </div>
                          <div class="countryContent">
                            <h3>
                              <a>{data.title}</a>
                            </h3>
                            <p>{data.description.slice(0, 70)}..</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <section class="teamSection01 ">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h5 class="subTitle">Professional People</h5>
                  <h2 class="secTitle">
                    Meet Our Expert Visa
                    <br />
                    Consultants
                  </h2>
                </div>
              </div>
              <div class="row">
                {teams.map((data, i) => {
                  return (
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3" key={i}>
                      <div class="teamItem01">
                        <div class="teamThumb">
                          <img src={api + data.image} alt="thefortuneimmigrations" style={{height:"400px"}} />
                          <a class="share" href="javascript:void(0);">
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_129_99)">
                                <path
                                  d="M6.66667 26.6666C3 26.6666 0 23.6666 0 19.9999C0 16.3333 3 13.3333 6.66667 13.3333C10.3333 13.3333 13.3333 16.3333 13.3333 19.9999C13.3333 23.6666 10.3333 26.6666 6.66667 26.6666ZM6.66667 16.6666C4.83333 16.6666 3.33333 18.1666 3.33333 19.9999C3.33333 21.8333 4.83333 23.3333 6.66667 23.3333C8.5 23.3333 10 21.8333 10 19.9999C10 18.1666 8.5 16.6666 6.66667 16.6666Z"
                                  fill="white"
                                />
                                <path
                                  d="M33.3333 13.3333C29.6667 13.3333 26.6667 10.3333 26.6667 6.66667C26.6667 3 29.6667 0 33.3333 0C37 0 40 3 40 6.66667C40 10.3333 37 13.3333 33.3333 13.3333ZM33.3333 3.33333C31.5 3.33333 30 4.83333 30 6.66667C30 8.5 31.5 10 33.3333 10C35.1667 10 36.6667 8.5 36.6667 6.66667C36.6667 4.83333 35.1667 3.33333 33.3333 3.33333Z"
                                  fill="white"
                                />
                                <path
                                  d="M33.3333 40.0001C29.6667 40.0001 26.6667 37.0001 26.6667 33.3334C26.6667 29.6667 29.6667 26.6667 33.3333 26.6667C37 26.6667 40 29.6667 40 33.3334C40 37.0001 37 40.0001 33.3333 40.0001ZM33.3333 30.0001C31.5 30.0001 30 31.5001 30 33.3334C30 35.1667 31.5 36.6667 33.3333 36.6667C35.1667 36.6667 36.6667 35.1667 36.6667 33.3334C36.6667 31.5001 35.1667 30.0001 33.3333 30.0001Z"
                                  fill="white"
                                />
                                <path
                                  d="M11.9502 20.7776L29.6892 29.6471L28.1985 32.6284L10.4596 23.7589L11.9502 20.7776Z"
                                  fill="#fff"
                                />
                                <path
                                  d="M28.0498 7.44458L29.5404 10.4259L11.8015 19.2954L10.3108 16.314L28.0498 7.44458Z"
                                  fill="#fff"
                                />
                              </g>
                            </svg>
                          </a>
                          <div class="teamSocial">
                            <a href={data.facebook}>
                              <i class="fa-brands fa-facebook-f"></i>
                            </a>
                            <a href={data.twitter}>
                              <i class="fa-brands fa-twitter"></i>
                            </a>
                            <a href={data.instagram}>
                              <i class="fa-brands fa-instagram"></i>
                            </a>
                          </div>
                        </div>
                        <div class="teamContent">
                          <h3>
                            <a>{data.name}</a>
                          </h3>
                          <h5 class="designation">{data.designation}</h5>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <section
            class="serviceSection01 abPageService"
            style={{ backgroundImage: `url(${sev1})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h5 class="subTitle">Visa Categories </h5>
                  <h2 class="secTitle">Outstanding Visa Services.</h2>
                </div>
              </div>
              <div class="row">
                {visits.map((data, i) => {
                  return (
                    <div class="col-lg-4 col-md-6 col-sm-6" key={i}>
                      <div class="serviceItem01 text-end">
                        <div class="serThumb">
                          <img src={api + data.image} alt="thefortuneimmigrations" />
                        </div>
                        <div class="serContent text-center">
                          <div class="serIcon">
                            <i class="flaticon-travel"></i>
                          </div>
                          <h3>
                            <a>{data.title}</a>
                          </h3>
                          <p>{data.description.slice(0, 117)}..</p>
                        </div>
                        <a
                          class="immiPressBtn"
                          type="button"
                          onClick={() => {
                            databyid2(data);
                          }}
                        >
                          <span>Explore More</span>
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <div style={{ marginBottom: "10px" }}>
            <h5 style={{ textAlign: "center", color: "red" }}>
              Our Testimonials
            </h5>
            <h2 style={{ textAlign: "center", fontSize: "50px" }}>
              What Customers Saying
              <br />
              About ImmiPress
            </h2>
          </div>

          <Slider
            {...settings}
            className="mt-5 mb-5"
            style={{ marginLeft: "15%" }}
          >
            {testimoni.map((data, i) => {
              return (
                <div className="container" key={i}>
                  <div style={{ display: "flex" }}>
                    <img
                      src={api + data.image}
                      alt="thefortuneimmigrations"
                      style={{ borderRadius: "50%", marginRight: "20px" }}
                    />
                    <div>
                      <h4>{data.name}</h4>
                      <span>{data.designation}</span>
                    </div>
                    <img
                      src="assets/images/home3/quote.png"
                      alt="thefortuneimmigrations"
                      style={{ display: "inline", marginLeft: "10%" }}
                    />
                  </div>
                  <p style={{ width: "70%", marginTop: "30px" }}>
                    {data.description}
                  </p>
                </div>
              );
            })}
          </Slider>

           <Footer contactus={contactus} visits={visits} />
        </>
      )}
    </div>
  );
}

export default Aboutus;
