import React, { useState, useEffect } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header";
import axios from "axios";

function Terms() {
  const [contactus, setcontactus] = useState([]);

  const [Terms, setTerms] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [visits, setvisits] = useState([]);

  useEffect(() => {
    datas();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const api = "https://api.thefortuneimmigrations.com/";

  const datas = () => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setcontactus(res.data.contactus[0]);
            setvisits(res.data.visits);
            setTerms(res.data.setting[0].termsAndCondition);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <section class="preloader" id="preloader">
            <div class="spinner-eff spinner-eff-1">
              <div class="bar bar-top"></div>
              <div class="bar bar-right"></div>
              <div class="bar bar-bottom"></div>
              <div class="bar bar-left"></div>
            </div>
          </section>
        </>
      ) : (
        <>
          <Header contactus={contactus} />
          <section
            class="pageBanner"
            style={{ backgroundImage: `url(${api + contactus.bannerImage})` }}
          >
            <div class="overlay"></div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="bannerTitle">Terms & Conditions</h2>
                  <div class="breadcrumbs">
                    <a>Home</a>
                    <span>/</span>Terms & Conditions
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="contactSection02">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="contactFWrapper">
                    <h5 class="subTitle">Terms & Conditions</h5>
                    <div
                      className="mt-5"
                      dangerouslySetInnerHTML={{
                        __html: Terms,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

           <Footer contactus={contactus} visits={visits} />
        </>
      )}
    </div>
  );
}

export default Terms;
