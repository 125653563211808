import React from "react";
import getbg from "../../assets/images/bg/getbg.png";
import { NavLink } from "react-router-dom";

function Footer(props) {
  const api = "https://api.thefortuneimmigrations.com/";

  const databyid2 = (data) => {
    sessionStorage.setItem("visits", data._id);
    window.location.href = "/visit";
  };

  return (
    <div>
      <footer class="footer_01" style={{ backgroundImage: `url(${getbg})` }}>
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 hideM">
              <div class="tj-stretch-element-inside-column">
                <div class="straceThumb">
                  <img
                    src={api + props.contactus.image}
                    alt="thefortuneimmigrations"
                  />
                </div>
              </div>
            </div>

            <div class="col-xl-9 col-md-12">
              <div class="row">
                <div class="col-lg-3 col-md-3">
                  <aside class="aboutWidget">
                    <a href="index.html">
                      <img
                        src={api + props.contactus.logo}
                        alt="thefortuneimmigrations"
                        style={{ background: "white" }}
                      />
                    </a>
                    <p>{props.contactus.footerDescription}</p>
                  </aside>
                </div>

                <div class="col-lg-4 col-md-4">
                  <aside class="widget serviceMenu">
                    <h3 class="widgetTitle">Service Visa</h3>
                    <ul class="menu">
                      {props.visits.map((data, i) => {
                        return (
                          <li key={i}>
                            <a
                              onClick={() => {
                                databyid2(data);
                              }}
                              type="button"
                            >
                              {data.title}
                            </a>
                          </li>
                        );
                      })}
                      <li>
                        <NavLink to="/Terms"> Terms & Conditions</NavLink>
                      </li>
                    </ul>
                  </aside>
                </div>

                <div class="col-lg-5 col-md-5">
                  <aside class="contactWidget">
                    <h3 class="widgetTitle">Get in touch</h3>
                    <p>
                      <span>Address:</span> {props.contactus.addresss}
                    </p>
                    <p>
                      <span>Front Desk:</span> {props.contactus.phone}
                    </p>
                    <p>
                      <span>Email:</span> {props.contactus.email}
                    </p>
                    <div class="abSocial">
                      <a href={props.contactus.facebook}>
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                      <a href={props.contactus.twitter}>
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                      <a href={props.contactus.instagram}>
                        <i class="fa-brands fa-instagram"></i>
                      </a>

                      <a
                        href={`https://api.whatsapp.com/send/?phone=${props.contactus.whatsapp}&text=Hi&type=phone_number&app_absent=0`}
                        target="_blank"
                      >
                        <i class="fa-brands fa-whatsapp"></i>
                      </a>
                    </div>
                  </aside>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="brhr"></div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <p class="copyright" className="text-center" style={{color:"white"}}>
                    {/* Copyright 2024 by The fortune Immigrations All Right
                    Reserved. */}
                    © 2024. The fortune Immigrations. All Rights Reserved - Designed by
                    <a
                      href="https://digitalraiz.co.in/"
                      className="p-1"
                      target="_blank"
                    >
                       Digitalraiz
                    </a>
                  </p>
                </div>
                {/* <div class="col-md-4">
                  <div class="crMenu">
                    <ul class="menu">
                      <li>
                        Designed by 
                        <a href="https://digitalraiz.co.in/" className="p-1" target="_blank">
                           Digitalraiz creative solutions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
