import React from "react";
import "./App.css";
import "../src/assets/css/main.css";
import "../src/ASSERTS/css/slider.css";
import Study from "./Components/Study/Study";
import Home from "./Components/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aboutus from "./Components/About us/Aboutus";
import Contact from "./Components/Contactus/Contact";
import Migrate from "./Components/Migrate/Migrate";
import Visit from "./Components/Visit/Visit";
import 'react-toastify/dist/ReactToastify.css';
import Terms from "./Components/Terms";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/study" element={<Study />} />
          <Route path="/migrate" element={<Migrate />} />
          <Route path="/visit" element={<Visit />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
