import React, { useState, useEffect } from "react";
import Header from "../Header";
import study3 from "../../assets/images/single-service/study3.jpg";
import Footer from "../Footer/Footer";
import axios from "axios";
import Accordion from "react-bootstrap/Accordion";

function Migrate() {
  const [Study, setStudy] = useState([]);

  const [Studys, setstudys] = useState([]);

  const [faq, setfaq] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    datas();
    contact();
    datas3();
  }, []);

  const getid = sessionStorage.getItem("visits");

  const api = "https://api.thefortuneimmigrations.com/";

  const datas3 = () => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getallvisits",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setstudys(res.data.visits);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const datas = () => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getvisitbyid",
        { _id: getid },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setStudy(res.data.data);
            setfaq(res.data.faqdata);
            // setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const databyid = (data) => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getvisitbyid",
        { _id: data._id },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setStudy(res.data.data);
            setfaq(res.data.faqdata);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const [contactus, setcontactus] = useState([]);

  const [visits, setvisits] = useState([]);

  const contact = () => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setcontactus(res.data.contactus[0]);
            setvisits(res.data.visits);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <section class="preloader" id="preloader">
            <div class="spinner-eff spinner-eff-1">
              <div class="bar bar-top"></div>
              <div class="bar bar-right"></div>
              <div class="bar bar-bottom"></div>
              <div class="bar bar-left"></div>
            </div>
          </section>
        </>
      ) : (
        <>
          <Header  contactus={contactus}/>
          <section
            class="pageBanner"
            style={{ backgroundImage: `url("${api + Study.bannerImage}")` }}
          >
            <div class="overlay"></div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="bannerTitle">{Study.title}</h2>
                  <div class="breadcrumbs">
                    <a>Home</a>
                    <span>/</span>Visit<span>/</span>
                    {Study.title}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="mt-5 mb-5">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="serviceDConArea">
                    <ul class="nav countryTabTabsNav" role="tablist">
                      <li role="presentation">
                        <button
                          class="active"
                          id="immigration-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#immigration-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="immigration-tab-pane"
                          aria-selected="false"
                        >
                         About
                        </button>
                      </li>
                      <li role="presentation">
                        <button
                          id="visitor-visas-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#visitor-visas-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="visitor-visas-tab-pane"
                          aria-selected="true"
                        >
                         visa info
                        </button>
                      </li>

                      <li role="presentation">
                        <button
                          id="education-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#education-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="education-tab-pane"
                          aria-selected="false"
                        >
                          FAQ'S
                        </button>
                      </li>
                    </ul>

                    <div class="tab-content ">
                      <div
                        class="tab-pane fade animated fadeIn active show"
                        id="immigration-tab-pane"
                        role="tabpanel"
                        aria-labelledby="immigration-tab"
                        tabindex="0"
                      >
                        <div class="row">
                          <div class="col-lg-8">
                            <div class="row ">
                              <div class="col-lg-12 col-sm-12">
                                <img
                                  src={api + Study.image}
                                  alt="thefortuneimmigrations"
                                  style={{ width: "100%" }}
                                />
                              </div>
                              <div class="col-lg-12 col-sm-12 mt-5">
                                <div class="sDcon">
                                  <h5>{Study.title}</h5>

                                  <p>{Study.description}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <aside class="widget serviceWidget cwIcon">
                              <h3 class="widgetTitle">All Study Details</h3>
                              <ul>
                                {Studys.map((data, i) => {
                                  return (
                                    <li
                                      key={i}
                                      onClick={() => {
                                        databyid(data);
                                      }}
                                    >
                                      <a type="button" className="listcolor">
                                        {data.title}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </aside>

                            <aside
                              class="widget contacInfotWidget"
                              style={{ backgroundImage: `url(${study3})` }}
                            >
                              <div class="infoContent">
                                <h5 class="ibTitle">Call for an Appointment</h5>

                                <h2>{contactus.phone}</h2>
                              </div>
                              <img
                                src="assets/images/single-service/1.png"
                                alt="thefortuneimmigrations"
                              />
                            </aside>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade animated fadeIn"
                        id="visitor-visas-tab-pane"
                        role="tabpanel"
                        aria-labelledby="visitor-visas-tab-pane"
                      >
                        <div class="row">
                          <div class="col-lg-8">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: Study.largeDescription,
                              }}
                              class="sDcon"
                            ></div>
                          </div>
                          <div class="col-lg-4">
                            <aside class="widget serviceWidget cwIcon">
                              <h3 class="widgetTitle">All Study Details</h3>
                              <ul>
                                {Studys.map((data, i) => {
                                  return (
                                    <li
                                      key={i}
                                      onClick={() => {
                                        databyid(data);
                                      }}
                                    >
                                      <a type="button" className="listcolor">
                                        {data.title}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </aside>

                            <aside
                              class="widget contacInfotWidget"
                              style={{ backgroundImage: `url(${study3})` }}
                            >
                              <div class="infoContent">
                                <h5 class="ibTitle">Call for an Appointment</h5>

                                <h2>{contactus.phone}</h2>
                              </div>
                              <img
                                src="assets/images/single-service/1.png"
                                alt="thefortuneimmigrations"
                              />
                            </aside>
                          </div>
                        </div>
                      </div>

                      <div
                        class="tab-pane fade animated fadeIn"
                        id="education-tab-pane"
                        role="tabpanel"
                        aria-labelledby="education-tab-pane"
                      >
                        <div class="row">
                          <div class="col-lg-8">
                            <h3>FAQ'S</h3>
                            <Accordion defaultActiveKey="0" className="mt-5">
                              {faq.map((data, i) => {
                                return (
                                  <Accordion.Item eventKey={i}>
                                    <Accordion.Header>
                                      {data.question}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {data.answer}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                );
                              })}
                            </Accordion>
                          </div>
                          <div class="col-lg-4">
                            <aside class="widget serviceWidget cwIcon">
                              <h3 class="widgetTitle">All Study Details</h3>
                              <ul>
                                {Studys.map((data, i) => {
                                  return (
                                    <li
                                      key={i}
                                      onClick={() => {
                                        databyid(data);
                                      }}
                                    >
                                      <a type="button" className="listcolor">
                                        {data.title}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </aside>

                            <aside
                              class="widget contacInfotWidget"
                              style={{ backgroundImage: `url(${study3})` }}
                            >
                              <div class="infoContent">
                                <h5 class="ibTitle">Call for an Appointment</h5>

                                <h2>{contactus.phone}</h2>
                              </div>
                              <img
                                src="assets/images/single-service/1.png"
                                alt="thefortuneimmigrations"
                              />
                            </aside>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer  contactus={contactus}  visits={visits} />
        </>
      )}
    </div>
  );
}

export default Migrate;
