import React, { useState, useEffect } from "react";
import countrybg from "../../assets/images/bg/countrybg.jpg";
import eligiblebg from "../../assets/images/bg/eligiblebg.png";
import { ToastContainer, toast } from "react-toastify";
import sev1 from "../../assets/images/bg/sev1.jpg";
import Footer from "../Footer/Footer";
import Slider from "react-slick";
import Header from "../Header";
import axios from "axios";
import { NavLink } from "react-router-dom";

function Home() {
  const settings = {
    dots: true,
    infinite: true,
    dots: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  useEffect(() => {
    datas();
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const [Sliders, setSliders] = useState([]);

  const [About, setAbout] = useState([]);

  const [Study, setStudy] = useState([]);

  const [contactus, setcontactus] = useState([]);

  const [migrates, setmigrates] = useState([]);

  const [teams, setteams] = useState([]);

  const [visits, setvisits] = useState([]);

  const [testimoni, settestimoni] = useState([]);

  const api = "https://api.thefortuneimmigrations.com/";

  const datas = () => {
    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setSliders(res.data.slides);
            setAbout(res.data.aboutus[0]);
            setStudy(res.data.studys);
            setteams(res.data.teams);
            setcontactus(res.data.contactus[0]);
            setvisits(res.data.visits);
            setmigrates(res.data.migrates);
            settestimoni(res.data.testimoni);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  const databyid = (data) => {
    sessionStorage.setItem("study", data._id);
    window.location.href = "/study";
  };

  const databyid1 = (data) => {
    sessionStorage.setItem("migrate", data._id);
    window.location.href = "/migrate";
  };

  const databyid2 = (data) => {
    sessionStorage.setItem("visits", data._id);
    window.location.href = "/visit";
  };

  const [form, setform] = useState([]);

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addcontactForm();
  };

  const addcontactForm = () => {
    const dataArray = {
      name: form.name,
      email: form.email,
      phone: form.phone,
      subject: form.subject,
      message: form.message,
    };

    axios
      .post(
        "https://api.thefortuneimmigrations.com/v1/fortuneImmigartionsapi/web/enquiry/addenquiry",
        dataArray,
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast("I'll get back to you soon");
            setform({
              name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <>
      {isLoading == true ? (
        <>
          <section class="preloader" id="preloader">
            <div class="spinner-eff spinner-eff-1">
              <div class="bar bar-top"></div>
              <div class="bar bar-right"></div>
              <div class="bar bar-bottom"></div>
              <div class="bar bar-left"></div>
            </div>
          </section>
        </>
      ) : (
        <>
          <Header contactus={contactus} />

          <Slider {...settings}>
            {Sliders.map((data, i) => {
              return (
                <>
                  <div key={i}>
                    <section
                      class="heroSection01"
                      style={{ backgroundImage: `url(${api + data.image})` }}
                    >
                      <div class="heroSlider01 owl-carousel">
                        <div
                          class="singleSlide bg-img d-flex align-items-center"
                          data-bg-image="assets/images/slider/1_1.jpg"
                        >
                          <div class="container">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="sliderContent">
                                  <h5 class="subTitle animated">
                                    {data.title}
                                  </h5>
                                  <h2 class="animated">
                                    {data.description.slice(0, 16)} <br></br>
                                    {data.description.slice(16, 30)} <br></br>
                                    {data.description.slice(30)}
                                  </h2>
                                  <div class="btnInfo">
                                    <NavLink to="/aboutus">
                                      <a class="immiPressBtn animated">
                                        {" "}
                                        <span>Read More</span>{" "}
                                      </a>
                                    </NavLink>
                                    <div class="iconBox01 animated">
                                      <div class="ibBox">
                                        <i class="flaticon-phone-call"></i>
                                      </div>
                                      <span class="ibTitle">Call Us</span>
                                      <p>{contactus.phone}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="hsShape01 animated">
                            <img
                              src="assets/images/slider/shape.png"
                              alt="thefortuneimmigrations"
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </>
              );
            })}
          </Slider>
          

          <section
            class="serviceSection01"
            style={{ backgroundImage: `url(${sev1})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h5 class="subTitle">Visa Categories </h5>
                  <h2 class="secTitle">Outstanding Visa Services.</h2>
                </div>
              </div>
              <div class="row">
                {visits.map((data, i) => {
                  return (
                    <div class="col-lg-4 col-md-6 col-sm-6" key={i}>
                      <div class="serviceItem01 text-end">
                        <div class="serThumb">
                          <img
                            src={api + data.image}
                            alt="thefortuneimmigrations"
                          />
                        </div>
                        <div class="serContent text-center">
                          <div class="serIcon">
                            <i class="flaticon-travel"></i>
                          </div>
                          <h3>
                            <a>{data.title}</a>
                          </h3>
                          <p>{data.description.slice(0, 117)}..</p>
                        </div>
                        <a
                          class="immiPressBtn"
                          type="button"
                          onClick={() => {
                            databyid2(data);
                          }}
                        >
                          <span> Explore More</span>
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <section class="aboutSection01">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="abVideo">
                    <img
                      src={api + About.image}
                      alt="thefortuneimmigrations"
                      style={{ height: "500px", width: "100%" }}
                      className="mt-5"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="aboutContent01">
                    <h5 class="subTitle">{About.title1}</h5>
                    <h2 class="secTitle">{About.tiltle2.slice(0, 34)}...</h2>
                    <p>{About.description.slice(0, 275)}</p>
                    <div class="row cusrow">
                      <div class="col-md-6 col-lg-12 col-xl-6 col-sm-6">
                        <div class="iconBox02">
                          <div class="ibBox">
                            <img
                              src={api + About.missionImage}
                              style={{ height: "40px" }}
                              alt="thefortuneimmigrations"
                            />
                          </div>
                          <h3 class="ibTitle">
                            {" "}
                            {About.missionTitle.slice(0, 30)}
                          </h3>
                          <p>{About.missionDescription.slice(0, 39)}...</p>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-12 col-xl-6 col-sm-6">
                        <div class="iconBox02">
                          <div class="ibBox">
                            <img
                              src={api + About.vissionImage}
                              style={{ height: "40px" }}
                              alt="thefortuneimmigrations"
                            />
                          </div>
                          <h3 class="ibTitle">
                            {About.vissionTitle.slice(0, 30)}
                          </h3>
                          <p>{About.vissionDescription.slice(0, 38)}..</p>
                        </div>
                      </div>
                    </div>
                    <div class="btnSign">
                      <NavLink to="/aboutus">
                        <a class="immiPressBtn" type="button">
                          {" "}
                          <span>Discover More</span>
                        </a>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="CTASection01">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="ctaWrapper">
                    <div class="row">
                      <div class="col-lg-5">
                        <h2>Have any Questions? Ask a Specialist</h2>
                        <p>
                          Nunc mi ipsum faucibus vitae. Mauris vitae ultricies
                        </p>
                        <div class="iconBox02">
                          <div class="ibBox">
                            <i class="flaticon-phone-call"></i>
                          </div>
                          <h3 class="ibTitle">
                            <a href={contactus.phone}>{contactus.phone}</a>
                          </h3>
                        </div>
                      </div>
                      <div class="col-lg-7">
                        <img
                          src="assets/images/home1/cta.png"
                          alt="thefortuneimmigrations"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            class="countrySection01"
            style={{ backgroundImage: `url(${countrybg})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h5 class="subTitle white">Choose Country</h5>
                  <h2 class="secTitle white">
                    Immigration and citizenship
                    <br />
                    Choose your <span>country!</span>
                  </h2>
                </div>
              </div>
              <div class="row">
                {migrates.map((data, i) => {
                  return (
                    <div class="col-lg-3 col-md-6 col-sm-6" key={i}>
                      <a
                        type="button"
                        onClick={() => {
                          databyid1(data);
                        }}
                      >
                        <div class="countryItem01 text-center">
                          <div class="countryThumb">
                            <img
                              src={api + data.image}
                              alt="thefortuneimmigrations" style={{height:"250px"}}
                            />
                          </div>
                          <div class="countryContent">
                            <h3>
                              <a>{data.title}</a>
                            </h3>
                            <p>{data.description.slice(0, 70)}..</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <section class="teamSection01">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h5 class="subTitle">Professional People</h5>
                  <h2 class="secTitle">
                    Meet Our Expert Visa
                    <br />
                    Consultants
                  </h2>
                </div>
              </div>
              <div class="row">
                {teams.map((data, i) => {
                  return (
                    <div class="col-lg-4 col-md-6 col-sm-6 mb-3" key={i}>
                      <div class="teamItem01">
                        <div class="teamThumb">
                          <img
                            src={api + data.image}
                            alt="thefortuneimmigrations" style={{height:"400px"}}
                          />
                          <a class="share" href="javascript:void(0);">
                            <svg
                              width="40"
                              height="40"
                              viewBox="0 0 40 40"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_129_99)">
                                <path
                                  d="M6.66667 26.6666C3 26.6666 0 23.6666 0 19.9999C0 16.3333 3 13.3333 6.66667 13.3333C10.3333 13.3333 13.3333 16.3333 13.3333 19.9999C13.3333 23.6666 10.3333 26.6666 6.66667 26.6666ZM6.66667 16.6666C4.83333 16.6666 3.33333 18.1666 3.33333 19.9999C3.33333 21.8333 4.83333 23.3333 6.66667 23.3333C8.5 23.3333 10 21.8333 10 19.9999C10 18.1666 8.5 16.6666 6.66667 16.6666Z"
                                  fill="white"
                                />
                                <path
                                  d="M33.3333 13.3333C29.6667 13.3333 26.6667 10.3333 26.6667 6.66667C26.6667 3 29.6667 0 33.3333 0C37 0 40 3 40 6.66667C40 10.3333 37 13.3333 33.3333 13.3333ZM33.3333 3.33333C31.5 3.33333 30 4.83333 30 6.66667C30 8.5 31.5 10 33.3333 10C35.1667 10 36.6667 8.5 36.6667 6.66667C36.6667 4.83333 35.1667 3.33333 33.3333 3.33333Z"
                                  fill="white"
                                />
                                <path
                                  d="M33.3333 40.0001C29.6667 40.0001 26.6667 37.0001 26.6667 33.3334C26.6667 29.6667 29.6667 26.6667 33.3333 26.6667C37 26.6667 40 29.6667 40 33.3334C40 37.0001 37 40.0001 33.3333 40.0001ZM33.3333 30.0001C31.5 30.0001 30 31.5001 30 33.3334C30 35.1667 31.5 36.6667 33.3333 36.6667C35.1667 36.6667 36.6667 35.1667 36.6667 33.3334C36.6667 31.5001 35.1667 30.0001 33.3333 30.0001Z"
                                  fill="white"
                                />
                                <path
                                  d="M11.9502 20.7776L29.6892 29.6471L28.1985 32.6284L10.4596 23.7589L11.9502 20.7776Z"
                                  fill="#fff"
                                />
                                <path
                                  d="M28.0498 7.44458L29.5404 10.4259L11.8015 19.2954L10.3108 16.314L28.0498 7.44458Z"
                                  fill="#fff"
                                />
                              </g>
                            </svg>
                          </a>
                          <div class="teamSocial">
                            <a href={data.facebook}>
                              <i class="fa-brands fa-facebook-f"></i>
                            </a>
                            <a href={data.twitter}>
                              <i class="fa-brands fa-twitter"></i>
                            </a>
                            <a href={data.instagram}>
                              <i class="fa-brands fa-instagram"></i>
                            </a>
                          </div>
                        </div>
                        <div class="teamContent">
                          <h3>
                            <a>{data.name}</a>
                          </h3>
                          <h5 class="designation">{data.designation}</h5>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <section
            class="contactSection01"
            style={{ backgroundImage: `url(${eligiblebg})` }}
          >
            {/* <div class="animThumb">
              <img
                src="assets/images/home1/s1.png"
                alt="thefortuneimmigrations"
              />
            </div> */}

            <div class="container">
              <div class="row">
                <div class="col-lg-6" id="gds">
                  <div class="contactFWrapper">
                    <h5 class="subTitle">Eligible to apply</h5>
                    <h2 class="secTitle">Check Your Eligibility</h2>
                    <div class="contactForm">
                      <form
                        id="eligibility_form_1"
                        onSubmit={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        <div class="row">
                          <div class="col-lg-6 col-md-12 col-sm-6">
                            <div class="input-box">
                              <input
                                type="text"
                                placeholder="Name"
                                name="name"
                                required
                                value={form.name}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12 col-sm-6">
                            <div class="input-box">
                              <input
                                type="email"
                                placeholder="Email address"
                                name="email"
                                required
                                value={form.email}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12 col-sm-6">
                            <div class="input-box">
                              <input
                                type="text"
                                minLength="10"
                                maxLength="10"
                                pattern="[0-9]+"
                                placeholder="Phone"
                                autocomplete="off"
                                name="phone"
                                required
                                value={form.phone}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12 col-sm-6">
                            <div class="input-box">
                              <input
                                type="text"
                                placeholder="Subject"
                                required
                                name="subject"
                                value={form.subject}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="input-box">
                              <textarea
                                placeholder="Message"
                                required
                                name="message"
                                value={form.message}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <button type="submit" class="immiPressBtn">
                              <span>Send Message</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="conThumb">
                    <img
                      src="assets/images/slider/bs2.png"
                      className="mb-5"
                      alt="thefortuneimmigrations"
                      style={{ height: "830px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="coachingSction01">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <h5 class="subTitle">Coaching We Offer</h5>
                  <h2 class="secTitle">
                    Get The Immigration Training
                    <br />
                    You Deserve
                  </h2>
                  <img
                    class="animImage"
                    src="assets/images/bg/s1.png"
                    alt="thefortuneimmigrations"
                  />
                </div>
              </div>

              <div class="row">
                {Study.map((data, i) => {
                  return (
                    <div class="col-lg-3 col-md-6 col-sm-6" key={i}>
                      <div class="coachingItem01">
                        <div class="cItWrapper">
                          <div class="coachingThumb">
                            <img
                              src={api + data.image} style={{height:"250px"}}
                              alt="thefortuneimmigrations"
                            />
                          </div>
                          <div class="coachingContent">
                            <h3>
                              <a>{data.title}</a>
                            </h3>
                            <p>{data.description.slice(0, 70)}..</p>
                          </div>
                        </div>
                        <a
                          class="immiPressBtn"
                          type="button"
                          onClick={() => {
                            databyid(data);
                          }}
                        >
                          <span>View Details</span>
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <section
            class="testimonialSection01"
            style={{ backgroundImage: `url(${"assets/images/bg/3.jpg"})` }}
          >
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="testimonialSlider01 owl-carousel">
                    <Slider {...settings1}>
                      {testimoni.map((data, i) => {
                        return (
                          <div class="testimonilaItem01" key={i}>
                            <div class="tsAuthor">
                              <img
                                src={api + data.image}
                                alt="thefortuneimmigrations"
                              />
                              <h5 class="tsNM">{data.name}</h5>
                              <span class="tsDesign">{data.designation}</span>
                            </div>
                            <div class="qutation">
                              {data.description.slice(0, 200)}
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <ToastContainer />
          <Footer contactus={contactus} visits={visits} />
        </>
      )}
    </>
  );
}

export default Home;
